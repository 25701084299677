<template>
  <b-container fluid>
    <div v-if="loading"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
    <div v-if="!loading">
      <h1>Autotags Stats</h1>
      <h3>Topics</h3>
      <div class="my-4">
        <b-form inline>
          from date:
          <b-form-input class="mx-4" type="date" v-model="date" />
          <b-button @click="load">load</b-button>
        </b-form>
      </div>
      <b-table id="table-topics" striped hover :items="topics" :fields="fields"></b-table>
    </div>
  </b-container>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

export default {
  created: function () {
    document.title = "Auto Tags Stats"
    this.load()
  },
  data () {
    return {
      fields: [
        { key: 'name', sortable: true },
        { key: 'allTagged', sortable: true },
        { key: 'autoTagged', sortable: true },
        { key: 'ratio', sortable: true },
        { key: 'allRemoved', sortable: true },
        { key: 'removeRatio', sortable: true },
        { key: 'error', sortable: true },
        { key: 'duplicate', sortable: true },
        { key: 'other', sortable: true }
      ],
      date: moment().subtract(3, 'months').format('YYYY-MM-DD'),
      loading: true,
      tags: [],
      topics: []
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        const response = await this.$Amplify.API.get('cosmos', `/suggestrules/stats/from/${this.date}`)
        response.topicAll.forEach(x => {
          x.ratio = _.round(x.autoTagged / x.allTagged, 2)
          if (!x.allRemoved) {
            x.allRemoved = 0
          }
          if (x.autoTagged === 0) {
            x.removeRatio = 0
          } else {
            x.removeRatio = _.round(x.allRemoved / x.autoTagged, 2)
          }
        })
        this.topics = response.topicAll
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    }
  }
}
</script>

<style></style>
